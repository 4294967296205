var isLazyLoad = (typeof is_lazy_load !== 'undefined' && is_lazy_load==false) ? false : true;

function setSticky(waypointTarget, stickyElTarget) {
    $(window).on("scroll", function() {
        header = $('#site_head_wrap');
        scrollDistance = $(window).scrollTop();
        stickyWaypoint = $(waypointTarget).height();

        if (scrollDistance >= stickyWaypoint) {
            $(stickyElTarget).addClass('is-sticky');
            header.css('height', '0px');

            if ($('body').hasClass('product_page')) {
                $('.product_infos.not_achat_express').css('position', 'relative');
                $('.is-sticky #itm_name span').hide();
            }

            if ($('body').hasClass('step_1')) {
                $('.bill_line.total_produit label+.price').insertBefore($('#formPanier'));
            }
        } else {
            if ($('body').hasClass('product_page')) {
                // show span before removing is-sticky class
                $('.is-sticky #itm_name span').show();
            }
            $(stickyElTarget).removeClass('is-sticky');
            header.css('height', 'auto');

            if ($('body').hasClass('product_page')) {
                $('.product_infos.product_infos.not_achat_express').css('position', 'sticky');
            }

            if ($('body').hasClass('step_1')) {
                $('.formPanier_container>.price').insertAfter($('.bill_line.total_produit label'));
            }
        }
    });
}

if ($('body').hasClass('step_1')) {
    setSticky('#right_col_sticky', '.formPanier_container');
}

$(function() {

    /**
    * TOP CART ROLLOVER
    */
    var topCart = $("#cart_top");
    var topCartRollover = $(".topCartRollover");

    topCart.mouseover(function() {
        $('.right.holderRollovers').addClass('onRollover');
        fadeHeaderElems('right', 'add');
    }).mouseout(function() {
        $('.right.holderRollovers').removeClass('onRollover');
        fadeHeaderElems();
    });
    triggerRollover(topCart);

    /**
    * TOP WISHLIST ROLLOVER
    */
    var topWish = $("#wishlist_top");

    topWish.mouseover(function() {
        $('.right.holderRollovers').addClass('onRollover');
        $('#shad_menu').addClass('actif');
        fadeHeaderElems('right', 'add');
    }).mouseout(function() {
        $('.right.holderRollovers').removeClass('onRollover');
        $('#shad_menu').removeClass('actif');
        fadeHeaderElems();
    });
    triggerRollover(topWish);

    // smallscreens scrollbars
    if($('#wishlist_not_logged').length) {
        $('#wishlist_not_logged').overlayScrollbars({
            scrollbars: {
                autoHide: "leave",
                autoHideDelay: 0,
            }
        });
    }
    if($('#show_top_wish #cart_list').length) {
        $('#show_top_wish #cart_list').overlayScrollbars({
            scrollbars: {
                autoHide: "leave",
                autoHideDelay: 0,
            }
        });
    }

    if($('#wishlist_swiper').length) {
        var wishlistSwiper = new Swiper('#wishlist_swiper', {
            slidesPerView: 1.75,
            navigation: {
                nextEl: '.wishlist-next',
                prevEl: '.wishlist-prev',
            },
        });
    }

    if ($('#show_top_wish .prod_reassurance_swiper').length) {
        var reassuranceSwiper = new Swiper('#show_top_wish .prod_reassurance_swiper', {
            slidesPerView: 'auto',
            loop: true,
            speed: 4000,
            freeMode: true,
            autoplay: {
                delay: 1,
                disableOnInteraction: false,
            },
        });
    }

    $('.confirmPaiement').on('click', function() {

        $(this).next().addClass('loading');
        setTimeout(function() {
            $('.loader').removeClass('loading');
        },1000);
    });


    var topAccount = $('.block_top_item.my_account.is_logged');
    topAccount.mouseenter(function() {
        fadeHeaderElems('right', 'add');
    }).mouseleave(function() {
        fadeHeaderElems();
    });
    triggerRollover(topAccount);

    /**
    * SEARCH ROLLOVER
    */
    var topSearch = $('.block_top_item.search');

    topSearch.mouseover(function () {
        openSearch();
    }).mouseout(function () {
        closeSearch();
    });

    $('body').on('click', '.recap', function () {
        openSearch();
        $('#search_home').focus();
    });


    /* LANG SWITCH*/

    $('.wrap_lang').on({
        mouseenter: function () {
            $(this).parent('.left').addClass('onRollover');
            $(this).find('.lang_switch').addClass('hovering');
            $('#shad_menu').addClass('actif');
            fadeHeaderElems('left', 'add');
        },
        mouseleave: function () {
            $(this).parent('.left').removeClass('onRollover');
            $(this).find('.lang_switch').removeClass('hovering');
            $('#shad_menu').removeClass('actif');
            fadeHeaderElems();

        },
    });

    $('.footer_wrap_lang').on({
        mouseenter: function () {
            $(this).addClass('onRollover');
            $(this).find('.lang_switch').addClass('hovering');
        },
        mouseleave: function () {
            $(this).removeClass('onRollover');
            $(this).find('.lang_switch').removeClass('hovering');
        },
    });


    $('.customer_service').on({
        mouseenter: function () {
            $(this).parent('.left').addClass('onRollover');
            $(this).find('.top_item_cta').addClass('hovering');
            $('#shad_menu').addClass('actif');
            fadeHeaderElems('left', 'add');
        },
        mouseleave: function () {
            $(this).parent('.left').removeClass('onRollover');
            $(this).find('.top_item_cta').removeClass('hovering');
            $('#shad_menu').removeClass('actif');
            fadeHeaderElems();

        },
    });


    /* FOOTER SEO */
    if($('.footer .SEO').length) {
        // Append google avis to SEO footer
        var google_avis = document.querySelector('.footer .avis_verifie_fixed');
        var destination = document.querySelectorAll('.footer .SEO .col');
        if(destination.length) {
            destination = destination[destination.length - 1].querySelector('.bot');
            destination.appendChild(google_avis);
        }

    }

    /* HEADER STICKY SCROLL */
    function onWinScroll() {
        var header = $("#site_head_wrap");
        var filtreSticky = $("#rayon_sticky_block");
        var headerHeight = $('#site_head_wrap').height();
        var scrollTop = $(document).scrollTop();

        // Handle header sticky logic
        if (header) {
            if (scrollTop === 0) {
                header.removeClass("sticky");
            } else {
                header.addClass("sticky");
            }
        }

        // Handle filtreSticky logic
        if (filtreSticky) {
            const getPushHeight = $('#push_rayon_3 img').height();
            const searchHeight = $('#search_page .search_recap').outerHeight();
            const bandoRayon = $('#bandeau_rayon').length;
            const searchPage = $('#search_page').length;

            // Check if the bandeau_rayon exists and if scrollTop is beyond push height minus header height
            if (bandoRayon && scrollTop > (getPushHeight - headerHeight)) {
                $(filtreSticky).addClass("sticky").css('top', `${headerHeight}px`);
            }
            // Check if the sub category bloc and title exist and if scrollTop is beyond 267
            else if ($('.bloc_sub_categ + .page_title').length && scrollTop > 267) {
                $(filtreSticky).addClass("sticky").css('top', `${headerHeight}px`);
            }
            // Check if no specific elements exist and if scrollTop is beyond 50
            else if (!bandoRayon && !$('.bloc_sub_categ + .page_title').length && !searchPage && scrollTop > 50) {
                $(filtreSticky).addClass("sticky").css('top', `${headerHeight}px`);
            }
            // Check if search page exists and scrollTop is beyond searchHeight
            else if (searchPage && scrollTop > searchHeight) {
                $(filtreSticky).addClass("sticky").css('top', `${headerHeight}px`);
            }
            // Remove sticky class and reset top if no conditions are met
            else {
                $(filtreSticky).removeClass("sticky").css('top', 'auto');
            }
        }
    }

    // Bind scroll event
    $(window).on("scroll", onWinScroll);

    // Initialize on page load
    onWinScroll();


    /* RAYON **************************************************************************************/
    /**********************************************************************************************/
    // Apply Script only on classic rayon
    if ($('.page_rayon').length && ($('.rayon_lookbook').length == 0)) {
        // Add first product to bandeau
        appendToBandeau($('.container_products .item'));

        // Add class to add opacity on wrapper when items are moved into beandeau
        $('.category #site_global_wrap').addClass('fullyLoaded');

        // Handle filters stick to header
        var filters = $('#rayon_sticky_block');

        var filters_height = filters.outerHeight();
        // var filters_offset_bot = bandeau_offset_bot + filters_height;
        var filters_offset_top = filters.offset().top;
        var filters_offset_bot = filters_offset_top + filters_height;
        var header_height = $('#site_head_wrap').outerHeight();



        /** RAYON REASSURANCE SWIPER 2 */
        if ($("#scroll_items .prod_reassurance_swiper").length) {
            setTimeout(function() {
                positionReassu();
            }, 500);
            $(window).on('resize', positionReassu);
        }

        /* RAYON TG SWIPER */
        if ($('#swiper_suggestion').length) {
            // If more than 3 products, we activate the swiper
            if ($('.gondole_item').length > 3) {
                var rayonTG = new Swiper('#swiper_suggestion', {
                    slidesPerView: 4,
                    navigation: {
                        nextEl: '.gondole-next',
                        prevEl: '.gondole-prev',
                    },
                });
            } else {
                $('#gondole_derniers_articles_vus').addClass('noswiper');
            }
        }

    }

    // Apply same loading technique on inactive page
    if ($('.category').length && $('.page_rayon').length == 0 && ($('.rayon_lookbook').length == 0) ) {
        setTimeout(function () { $('.category #site_global_wrap').addClass('fullyLoaded') }, 300);
    }

    /* LOOKBOOK ***********************************************************************************/
    /**********************************************************************************************/
    if ($('.rayon_lookbook').length && $('.first_look_with_arrow').length) {
        // Like the rayon, we add fullyLoaded class after this action to properly show the page
        setTimeout(function() {$('.category #site_global_wrap').addClass('fullyLoaded')}, 300);

        $('.first_look_with_arrow').on('click', function() {
            // Scroll to first item of lookbook's list
            $("html, body").animate({
                scrollTop: $('.item.lookbook').eq(2).offset().top - $('#site_head_wrap').outerHeight(),
            }, 500);
        });

        // Gondoles Look logic
        if ($('.look_gondole_choice').length) {
            if ($(".swiper-container", ".holder_look_gondoles .wrapper_suggestion.active").length) {
                // If more than 3 products, we activate the swiper
                if ($('.holder_look_gondoles .wrapper_suggestion.active .gondole_item').length > 3) {
                    var rayonTG = new Swiper('.wrapper_suggestion.active .swiper-container', {
                        slidesPerView: 4,
                        navigation: {
                            nextEl: '.gondole-next',
                            prevEl: '.gondole-prev',
                        },
                    });
                }
            }

            $('.look_gondole_choice .title').on('click', function(e) {
                // We get title's tab number
                var title = $(this).attr('data-tab');
                var active_tab = $('.holder_look_gondoles .wrapper_suggestion.active');

                // Add active class if not already
                if (!$(this).hasClass('active')) {
                    $('.look_gondole_choice .title.active').removeClass('active');
                    $(this).addClass('active');
                }

                // If new active title, display matching gondole tab
                if (title != active_tab.attr('data-tab')) {
                    active_tab.removeClass('active');
                    $('.holder_look_gondoles .wrapper_suggestion[data-tab="'+ title +'"]').addClass('active');

                    if ($(".wrapper_suggestion .swiper-container" , '.holder_look_gondoles .wrapper_suggestion[data-tab="' + title + '"]')) {
                        // If more than 3 products, we activate the swiper
                        if ($('.gondole_item').length > 3) {
                                var rayonTG = new Swiper('.wrapper_suggestion[data-tab="' + title + '"] .swiper-container', {
                                    slidesPerView: 4,
                                    navigation: {
                                        nextEl: '.gondole-next',
                                        prevEl: '.gondole-prev',
                                    },
                                });
                        }
                    }
                }
            });
        }
    }

    /* SEARCH PAGE *********************************************************************************/
    if ($('.dynasearchwrapper').length) {
        $('body').addClass('onSearchPage')

        if ($('.search_gondole_swiper').length) {
            var search_gondole = new Swiper('.search_gondole_swiper', {
                slidesPerView: 4,
                navigation: {
                    nextEl: '.gondole-next',
                    prevEl: '.gondole-prev',
                },
            });
        }
    } else {
        $(document.body).on('change', '.main_wrapper > div', function() {
        });
    }

    /* HOMEPAGE ***********************************************************************************/
    /**********************************************************************************************/
    if ($('.homepage').length) {
        var coverSwiper = new Swiper('#super_home_swiper', {
            slidesPerView: 1,
            loop: true,
            navigation: {
                nextEl: '.cover-next',
                prevEl: '.cover-prev',
            },
            autoplay: false,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            }
        });

        if ($('.cover-module').length) {
            // Click is enabled only for first cover-module
            $('.cover_scroll_arrow').on('click', function() {
                // On click on first cover module arrow, scroll to next home-module top
                $("html, body").animate({
                    scrollTop: $('.cover-module').first().next('.home-module').offset().top - $('#site_head_wrap').outerHeight(),
                }, 500);
            });
        }

        // Look module 2 scrollbar
        if ($('.home_look_second').length) {
            $(window).on('load resize', function(){
                $('.home_look_second .holder_items').css({
                    'margin-top': $('.home_look_second .holder_scrollable').outerHeight() - ($('.home_look_second .holder_items .item').outerHeight() + 96),
                });
            })
            $('.home_look_second .holder_scrollable').overlayScrollbars({});
        }

        // imageTextSwiper module image-image-text
        if ($('.image-image-text').length) {
            var imageTextSwiper = new Swiper('.imageTextSwiper', {
                slidesPerView: 'auto',
                loop: true,
                speed: 8500,
                autoplay: {
                    delay: 10,
                },
            });
        }
    }

    if ($('#super_home_swiper').length) {
        var id = "super_home_swiper";

        // Cas Video Vimeo
        var vimeoPlayers = {};
        if ($('#' + id + ' .swiper-slide iframe.vimeo_video').length >= 1) {
            $('#' + id + ' .swiper-slide iframe.vimeo_video').each(function () {
                var videoId = $(this).attr('id');
                var options = { background: true, loop: true, autoplay: true };
                var playerVimeo = new Vimeo.Player($(this), options);

                //add a listener to start the slider autoplay again
                playerVimeo.on('ended', function () {
                    setTimeout(function () {
                        coverSwiper.autoplay.start();
                    }, 250);
                });
                vimeoPlayers[videoId] = playerVimeo;
            });
        }

        var youtubePlayers = {};
        if ($('#' + id + ' .swiper-slide iframe.youtube_video').length >= 1) {
            var tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            var firstScript = document.getElementsByTagName('script')[0];
            firstScript.parentNode.insertBefore(tag, firstScript);

            window.onYouTubeIframeAPIReady = function () {
                $('#' + id + ' .swiper-slide iframe.youtube_video').each(function () {
                    var videoId = $(this).attr('id');
                    var youtubePlayer = new YT.Player(videoId, {
                        //add a listener to start the slider autoplay again
                        events: {
                            onStateChange: function (evt) {
                                if (evt.data === YT.PlayerState.ENDED) {
                                    evt.target.seekTo(0);
                                    evt.target.pauseVideo();
                                    coverSwiper.autoplay.start();
                                }
                            }
                        }
                    });
                    youtubePlayers[videoId] = youtubePlayer;
                });

            };
        }

    }

    //ADD ":" on string - Contact page
    if ($('.contact_faq .w-lines-group .w-fieldset-label').length) {
        var originTxt = $('.contact_faq .w-lines-group .w-fieldset-label').text();
        newTxt = originTxt += ":";
        $('.contact_faq .w-lines-group .w-fieldset-label').text(newTxt);
    }

    /* BANDEAU DELIVERY **************/
    // Client wants the header_mention to be hidden on scroll
    if ($('#site_head_wrap.bandeau_on').length) {
            // On page load if scrollTop > 0 hiden bandeauDelivery
            if ($(window).scrollTop() > 0) {
                $('#header_mention_id').slideUp(100);
                $("#site_head_wrap").removeClass('bandeau_on');
            }

            $(window).on('scroll', function() {
                if ($(window).scrollTop() > 0) {
                    $('#header_mention_id').slideUp(100);
                    $("#site_head_wrap").removeClass('bandeau_on');
                } else {
                    $("#site_head_wrap").addClass('bandeau_on');
                    $('#header_mention_id').slideDown(100);
                }
            });
    }



    // Tunnel special script
    if ($('.formPanier_container').length && $('#cart_total.main_table').length) {
        $('.formPanier_container').insertAfter($('#cart_total.main_table'));

        if (!$('.formPanier_container').css('opacity') == '0') {
            $('.formPanier_container').addClass('visible');
        }
    }

});

function triggerShadStyle(fromSource) {
    fromSource.mouseenter(function(){
        $("#site_head_wrap").addClass('with_shad');
        $('#shad_menu').addClass('actif');
    }).mouseleave(function() {
        $("#site_head_wrap").removeClass('with_shad');
        $('#shad_menu').removeClass('actif');
    });
}

function triggerRollover(source) {
    source.mouseover(function() {
        $('.right.holderRollovers').addClass('onRollover');
    }).mouseout(function() {
        $('.right.holderRollovers').removeClass('onRollover');
    })
}

function fadeHeaderElems(from, action) {
    // Only if header background is transparent == On page top
    if(!$('#site_head_wrap.sticky').length) {
        if(action == 'add') {
            if(from == "right") {
                $('.block_top_links.left, .main_menu_wrapper, .block_top_links.logo').addClass('goTransparent');
            } else if(from == "left") {
                $('.block_top_links.right, .main_menu_wrapper, .block_top_links.logo').addClass('goTransparent');
            }
        } else {
            $('.goTransparent').removeClass('goTransparent');
        }
    }
}

function displayDropdownFilters(from_filtersSubmit) {
    from_filtersSubmit = from_filtersSubmit ? from_filtersSubmit : 0;

    if (from_filtersSubmit) {
        $('button.reinitialiser').addClass('FromFilter');
    }

    var sticky, filters_dropdown, filter_trigger, shad;
    sticky = $('#filter_sticky');
    filters_dropdown = sticky.find('.filters_dropdown');
    filter_trigger = $('#trigger_filtre')
    shad = $('#shad_link');

    if (!filter_trigger.hasClass('triggered')) {
        $('#rayon_sticky_block').addClass('triggered');
        filter_trigger.addClass('triggered');
        filters_dropdown.addClass('show drop');
        filter_trigger.addClass('toClose');
        shad.addClass('actif filterOn');

        var styles = {
            'top': filter_trigger.offset().top,
            'height': $('#appContainer').outerHeight() - filter_trigger.offset().top
        }

        shad.css(styles);
        shad.addClass('absolute');

        const getPushHeight = $('#push_rayon_3 img').height();
        const getTitleHeight = $('.bloc_sub_categ + .page_title').outerHeight();
        const headerHeight = $('#site_head_wrap').height();
        const searchHeight = $('#search_page .search_recap').outerHeight() + headerHeight;

        let checkPresence = getPushHeight || getTitleHeight || searchHeight;

        $('html, body').animate({
            scrollTop: $('#site_global_wrap').offset().top + checkPresence - (headerHeight - 10)
        }, 500);

    } else if (filter_trigger.hasClass('triggered')) {
        closeFiltre(from_filtersSubmit);
    }

    if (filter_trigger.hasClass('triggered')) {
        var shad_filtre = $('#shad_link.filterOn');

        shad_filtre.on("click touch", function () {
            closeFiltre(from_filtersSubmit);
        });
    }

    $(".filter_wrapper:not('#filter_trier') .selected_options").overlayScrollbars({});

    function closeFiltre(from_filtersSubmit) {
        if (filter_trigger.hasClass('triggered')) {
            $('#rayon_sticky_block').removeClass('triggered');
            filter_trigger.removeClass('triggered toClose');
            filters_dropdown.removeClass('show drop');
            shad.removeClass('actif filterOn absolute');
        }
    }
}

// item
function appendToBandeau(items) {
    var bandeau = $('.wrapper_bandeau');
    var holder_topProduct = bandeau.find('.holder_topProduct').html('');
    var wrap_bg = $('.wrapper_bandeau').find('.wrap_bg');

    var first_item = items[1];
    var first_push = items[0];

    if ($(first_push).attr('id') != 'push_rayon_3') {
        bandeau.remove();
        return;
    }

    wrap_bg.css({});
    if(wrap_bg.find('.item')) {
        wrap_bg.find('.item').remove();
    }
    wrap_bg.append(first_push);
    holder_topProduct.append(first_item);
}



function positionReassu() {

    // If theres not enough items to display reassu, hide it
    if ($('#scroll_items #list_item .item').length > 7) {
        var reassurance = $("#scroll_items .prod_reassurance_swiper");
        var item_pos;

        if ($('.items_wrapper').hasClass('bydefault')) {
            item_pos = 5;
        } else {
            if ($('.items_wrapper #push_rayon_4').length) {
                item_pos = 6;
            } else {
                // If push not present on view by 4, 2 products will take its place, so there's one more item_pos to count
                item_pos = 7;
            }
        }

        reassurance.insertAfter($('.container_products .item').eq(item_pos));

        var reassuranceSwiper = new Swiper('#scroll_items .prod_reassurance_swiper', {
            slidesPerView: 'auto',
            loop: true,
            speed: 4000,
            freeMode: true,
            autoplay: {
                delay: 1,
                disableOnInteraction: false,
            },
        });
    } else {
        $("#scroll_items .prod_reassurance_swiper").hide();
    }
}

function openSearch() {
    var search = $('.block_top_item.search');

    $("#site_head_wrap").addClass('with_shad');
    search.addClass('hovering');
    $('#shad_menu').addClass('actif');
}

function closeSearch() {
    var search = $('.block_top_item.search');

    $('#shad_menu').removeClass('actif');
    $("#site_head_wrap").removeClass('with_shad');
    search.removeClass('hovering');
}

/**
 * Scroll to top
 */

function scrollToTheTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}

/**
 * Dropdown function
 * Like accordion
 * For instance => used on FP product_feats_wrapper
 * Exemple : accordionDropdown('#prodFeatsWrapperAccordion li h3', 'p', '#prodFeatsWrapperAccordion li');
 */

function accordionDropdown(clickTarget, toShowTarget, toShowTargetParent) {
    $(clickTarget).on('click', function () {

        var subMenu = $(this).next(toShowTarget);

        $(clickTarget + '.is-clicked').removeClass('is-clicked');
        $(clickTarget + '.is-open').slideUp();

        if (subMenu.is(':visible')) {
            subMenu.slideUp();
            subMenu.removeClass('is-open');
        } else {
            $(this).addClass('is-clicked');
            $(toShowTargetParent +  ' ' + toShowTarget +'.is-open').slideUp();
            subMenu.slideDown();
            subMenu.addClass('is-open');
        }
    });
}

/**
 * SIZE FOR ACCOUNT / BASKET / WISHLIST ROLLOVER
 */
function setRollWidth() {
    var totalWidth = $(window).innerWidth();
    var rollAccountEntries = $('.rollAccountEntries');
    var entriesLeft = $('.block_top_links.left .rollAccountEntries');
    var entriesRight = $('.block_top_links.right .rollAccountEntries');
    var head_top = $('.head_top .header');
    var head_topWidth = head_top.outerWidth();
    var desiredWidth = 370 + (totalWidth - head_topWidth) / 2;

    if (totalWidth > 1680) {
        rollAccountEntries.css({
            'width' : desiredWidth + "px",
        });
        entriesLeft.css({
            'padding-left' : ((totalWidth - 1680)/2) + 'px',
        });
    } else {
        // We set 2rem padding to match small width integration
        rollAccountEntries.css({
            'width': desiredWidth + "px",
        });
        entriesLeft.css({
            'padding-left': 2 + 'rem',
        });
        entriesRight.css({
            'padding-right': 2 + 'rem',
        });
    }
}
window.onload = setRollWidth;
window.onresize = setRollWidth;

/* FAQ TUNNEL */
if ($('#faqTunnelList').length) {
    accordionDropdown('#faqTunnelList li .faq_tunnel_question', '.faq_tunnel_answer', '#faqTunnelList li');
}

/* TUNNEL */
if($("body.cart .prod_reassurance_swiper").length > 0) {
    var cartProdReassuranceSwiper = new Swiper('body.cart .prod_reassurance_swiper', {
        slidesPerView: 'auto',
        spaceBetween: 16,
        loop: true,
        speed: 4000,
        freeMode: true,
        autoplay: {
          delay: 1,
          disableOnInteraction: false,
        },
    });
}

$(document).ready(function() {
    var previousEl;

    if($('.step_1.cart .push_container').length){
        previousEl = $('.cart').find('.push_container');
    } else {
        previousEl = $('#main_cart_wrapper');
    }

    $('#cart_slider_wrapper').insertAfter(previousEl);

    if($("#cart_slider_wrapper").length > 0) {
        setTimeout(function() {
            var cartSliderWrapper = new Swiper('#cart_slider_wrapper', {
                slidesPerView: 4,
                loop: false,
                speed: 1500,
                autoplay: {
                    delay: 3000,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        }, 500);
    }

    $('#cart_slider_wrapper').css('height', 'auto');


    //Init overlayScrollbar return cmd

        $("#popup_numero_retour").overlayScrollbars({});
});

/** CART ROLLOVER REASSURANCE SWIPER */
if ($("#show_top_cart .prod_reassurance_swiper").length) {

    setTimeout(function() {
        var rolloverCartReassuranceSwiper = new Swiper('#show_top_cart .prod_reassurance_swiper', {
            slidesPerView: 'auto',
            loop: true,
            speed: 4000,
            freeMode: true,
            autoplay: {
              delay: 1,
              disableOnInteraction: false,
            },
        });

    }, 500);

}


//Shade on pinpoint hover - Lookbook
$('.item.lookbook.with_tags .tag .pinpoint').hover(function() {
    $(this).toggleClass('show_pinpoint');
    $(this).siblings('.tag_tooltip').toggleClass('show_tagtool');
    $(this).closest('.imgWrapper').toggleClass('shade_img');
});

//Shade on pinpoint hover - HomePage
$('.home-module.look-look.home_look_first .tag .pinpoint').hover(function() {
    $(this).toggleClass('show_pinpoint');
    $(this).siblings('.look_linked_products_pictures').toggleClass('show_tagtool');
    $(this).closest('.look_main_picture_container').toggleClass('shade_img');
});

//Shade on pinpoint hover - Page ADV
$('.cms-page-module.look-look .look_main_picture_container .pinpoint').hover(function() {
    $(this).toggleClass('show_pinpoint');
    $(this).siblings('.look_linked_products_pictures').toggleClass('show_tagtool');
    $(this).closest('.look_main_picture_container').toggleClass('shade_img');
});

//Add underline style - services - retouches
$('.satellite_nos_services .row.retouche #tilliBtnContainer').addClass('underline');


/***** Relax animation - ici sur la page histoire et savoir *****/
function relaxImg() {
    $(window).on("scroll", function() {
        scrollDistance = $(window).scrollTop();
        var relaxWaypoint = $('#histoire_banner').height()/2;
        var relaxTarget = $('.rellax.image_vertical');

        if (relaxWaypoint <= scrollDistance) {
            relaxTarget.addClass('is-relax');
        } else if (relaxTarget.hasClass('is-relax')) {
            relaxTarget.removeClass('is-relax');
        }
    });
}

if ($('body').hasClass('satellite_histoire')) {
    relaxImg();
}

if ($('#back_to_top').length) {
    $(window).on("scroll", function() {
        if ($(window).scrollTop() > 600) {
            $('#back_to_top').show("slow");
        } else {
            $('#back_to_top').hide("slow");
        }
    });
}

function submitToCalcul(e) {
    if (e.keyCode && e.keyCode == "13") {
        calculCofidis3Cb();
    } else {
        return;
    }
}

function initSearchSwiper(swiper) {

    if (swiper.hasClass('productSwiper')) {
        if (swiper.find('.swiper-slide-on').hasClass('cache')) {

            swiper.find('.swiper-wrapper').html(swiper.find('.swiper-slide-on').html());
            swiper.find('.swiper-slide-off').remove();
            swiper.find('.swiper-slide-on').remove();

            var productSwiper = new Swiper(swiper, {
                slidesPerView: 1,
                observer: true,
                observeParents: true,
                navigation: {
                    nextEl: '.swiper-next',
                    prevEl: '.swiper-prev',
                },
                preloadImages: false,
                lazy: true,
                loadPrevNext: true,
                loadOnTransitionStart: true
            });

        }
    }
}

// toggle page CSM
$(function() {
    $(".dropdown_cms").click( function () {
        $(this).next('ul.menu_cms').slideToggle();
    });

    //#content_obj present only in rayon & recherche
    if ($('#content_obj > .page_title')) {
        $('#content_obj > .page_title').insertBefore($('#rayon_sticky_block'));
    }
});